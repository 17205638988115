import ActionHandler from './ActionHandler';
import ActionProcessor, {ActionRequest} from '../ActionProcessor';

class RetrieveCachesAction implements ActionHandler {
    process(action: ActionRequest, processor: ActionProcessor): Promise<any|void> {
        if (!window.caches) return Promise.resolve(false);

        return window.caches.keys();
    }
}

export default RetrieveCachesAction
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Dialog from '../Dialog';
import LoadingSpinner from '../LoadingSpinner';
import Modal from '../Modal';

// Utils
import { apiRequest } from '../../services/API';

class Demo extends React.Component {
    render() {
        return <Modal>
            <div style={{margin:'30px auto', width:200, textAlign: 'center'}}>
                <Dialog title="Demo">
                    {this.state.loading ? <LoadingSpinner/> : null}
                    <p>{this.state.statusText}</p>
                </Dialog>
            </div>
        </Modal>
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            statusText: 'Verifying...'
        };
    }

    componentDidMount() {
        if (!this.props.code || this.props.code.length === 0) {
            this.setState({
                loading: false,
                statusText: 'No code specified in component'
            });
            return;
        }
        apiRequest('/kiosk/registration/democode.json?code=' + this.props.code)
            .then(response => {
                if (response.success) {
                    this.setState({
                        statusText: 'Verified'
                    })
                    this.props.onKiosk(response.kiosk);
                } else {
                    this.setState({
                        loading: false,
                        statusText: response.error
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    statusText: 'Failed to load'
                })
                console.log('An internal error occurred: ' + err);
            })
    }
}

Demo.propTypes = {
    code: PropTypes.string.isRequired,
    onKiosk: PropTypes.func.isRequired
};

export default Demo
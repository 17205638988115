export default {
    notRegisteredQuestion: 'Not registered?',
    registerHere: 'Register Here',
    clickHere: 'Click Here',
    email: 'Email',
    textPhone: 'Text Phone',
    passwordPrompt: 'Password:',
    signInTitle: 'Sign In',
    registrationTitle: 'Register',
    alreadyRegisteredQuestion: 'Already registered?',
    signInHere: 'Sign In Here',
    confirmPasswordPrompt: 'Confirm Password:',
    postalCodePrompt: 'Zip/postal code:',
    missingEmailAndPassword: 'Please enter an email and a password',
    logOut: 'Log Out',
    signIn: 'Sign In'
}

const general = {
    unknownErrorTitle: 'Unbekannter Fehler',
    unknownErrorDescription: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es in Kürze wieder.',
    loading: 'Wird geladen...',
    sending: 'Senden',
    sendEmail: 'E-Mail senden',
    sendText: 'Text senden',
    submittingDotDotDot: 'Einreichen...',
    backTo: 'Zurück',
    close: 'verschließen',
    restarting: 'Neustart',
    cancel: 'Kündigen',

    // Template
    travelResources: 'Ressourcen',
    travelInfoGuides: 'Reiseinfo Führungs',
    travelGuides: 'Reiseinfo Führungs',
    quickLinks: 'Nützliche Links',
    privacyPolicy: 'Datenschutzerklärung',
    numBrochuresSaved: '%d gespeichert',
    saving: 'Hinzufügen...',
    hotDeals: 'Rabatte',
    'Select City': 'Stadt wählen',
    'Select Area': 'Bereich ändern', // Change Area
    'Select a category below:': 'Wählen Sie unten eine Kategorie aus:',
    funAtYourFingerTips: 'Spaß an Ihren Fingerspitzen',
    qrCodeInstructions: 'Scannen Sie den QR-Code links mit Ihrem Handy oder Tablet, um die Website zu öffnen.',
    qrCodeHint: 'Tipp: Verwenden Sie die Kamera-App auf Ihrem iOS und Android',

    // Categories
    'All Brochures': 'Alle Broschüren',
    'Main Destination Rack': 'Main Destination Rack',
    'Accommodations': 'Unterkünfte',
    'Attractions & Activities': 'Sehenswürdigkeiten & Aktivitäten',
    'Dining & Entertainment': 'Essen & Ausgehen',
    'Shopping': 'Einkaufs',
    'Tours & Transportation': 'Touren & Transport',
    'Visitor Services': 'Besucherservice'
};

export default general
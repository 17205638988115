import React from 'react';
import QRCodeSvg from 'qrcode-svg';

/**
 * @param props Any option that can be set on QRCode - most import {content: 'https://www.domain.com/'}
 * @returns {*}
 * @constructor
 * @see https://www.npmjs.com/package/qrcode-svg
 */
function QRCode(props) {
    const qrcode = new QRCodeSvg(props);

    return <div className="QRCode" dangerouslySetInnerHTML={{__html: qrcode.svg()}}/>
}

export default QRCode
import React from 'react';
import PropTypes from 'prop-types';

// Assets
import './Share.css';

// Components
import Button from '../../../Button';
import CachedImage from '../../../CachedImage';

// Utilities
import { qrCodeUrl} from '../../../../utils/Url';
import { apiRequest }  from '../../../../services/API';

// Contexts
import { LocationContext } from '../../../../contexts/Location';
import LoadingSpinner from '../../../LoadingSpinner';

class Share extends React.Component {

    // $email_id = sprintf('share-email-%d', $this->listing->getId());
    // $phone_id = sprintf('share-text-%d', $this->listing->getId());
    // $share_friend_id = sprintf('share-friend-%d', $this->listing->getId());

    render() {
        return <LocationContext.Consumer>{({httpCache, translations}) => (
            <div>
                <h1><i className="ico-share"></i> {translations.brochureDetail.shareBrochure}</h1>

                <p>{translations.brochureDetail.shareBrochureDescription}</p>
                <div className="qrcode">{this.qrImg(httpCache)}</div>
                <div className="share-body">
                    {this.state.message.length > 0 ? <div>{this.state.message}</div> : null}
                    {this.state.sending ?
                        <div><LoadingSpinner/> {translations.general.sending}...</div>
                        :
                        null
                    }
                    {this.state.showForm ?
                        <>
                            <form method="post" onSubmit={() => false}>
                                <p><strong>{translations.account.email}: </strong>
                                    <input type="text" name="email" size="30"
                                           value={this.state.email}
                                           onChange={this.handleTextChange.bind(this)}
                                           className="brochure-Share-input"/> <Button
                                        label={translations.general.sendEmail} onClick={this.shareEmail.bind(this)}/>
                                </p>
                            </form>
                            <form method="post" onSubmit={() => false}>
                                <p><strong>{translations.account.textPhone}: </strong>
                                    <input type="text" name="phone" size="15"
                                           value={this.state.phone}
                                           onChange={this.handleTextChange.bind(this)}
                                           className="brochure-Share-input"/> <Button
                                        label={translations.general.sendText} onClick={this.shareText.bind(this)}/></p>
                            </form>
                        </>
                        :
                        null
                    }
                </div>
            </div>
        )}</LocationContext.Consumer>
    }

    qrImg(httpCache) {
        const brochure = this.props.brochure;

        const qrSize = 150;
        const qrContent = 'https://m.visitortips.com/search/detail.html?listingid=' + brochure.id;
        const src = qrCodeUrl(qrContent);

        return <CachedImage src={src} alt={'QR code for ' + brochure.name} width={qrSize} height={qrSize} />
    }

    handleTextChange(ev) {
        this.setState({
            [ev.target.name]: ev.target.value
        });
    }

    shareEmail() {
        this.share('email', this.state.email);
    }
    shareText() {
        this.share('phone', this.state.phone);
    }

    share(varName, val) {
        this.setState({
            sending: true,
            showForm: false,
            message: ''
        });
        const url = '/kiosk/share/brochure.json?' + varName + '=' + encodeURIComponent(val) + '&listing=' + encodeURIComponent(this.props.brochure.id);
        apiRequest(url)
            .then(response => {
                if (response.success) {
                    this.setState({
                        message: 'Message sent',
                        sending: false
                    }, () => {
                        // Delay showing form again to give user a chance to see "Message sent" message
                        this.timer = setTimeout(() => {
                           this.setState({
                               showForm: true,
                               message: ''
                           });
                        }, 3000);
                    });
                } else {
                    this.setState({
                        sending: false,
                        message: 'Failed to send.' + response.message
                    });
                }
            });
    }

    constructor(props, context) {
        super(props, context);
        this.timer = null;

        this.state = {
            email: '',
            phone: '',
            sending: false,
            showForm: true,
            message: ''
        };
    }
}

Share.propTypes = {
    brochure: PropTypes.object.isRequired
};

export default Share
import React from 'react';

import './CodeButton.css';

class CodeButton extends React.Component {
    render() {
        return <div className="setup-CodeButton" onClick={() => this.props.onClick(this.props.value)}>
            {this.props.value}
        </div>
    }
}

export default CodeButton
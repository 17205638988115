import React from 'react';
import Pocket from './Pocket';
import './Row.css';

class Row extends React.Component {
    render() {
        if (this.props.mockBrochures) return this.mockedBrochurePockets();

        const brochures = this.props.brochures.map((brochure, colIx) => {
           return <Pocket key={colIx}
                          brochure={brochure}
                          onBrochureClicked={brochure => this.props.onBrochureClicked(brochure, colIx)}
                  />
        });

        return <div className="rack-Row">{brochures}<div className="rack-Row-shadow"></div></div>
    }

    mockedBrochurePockets() {
        var brochures = [];
        for(let i=0; i < this.props.mockBrochures; i++) {
            brochures.push(<Pocket key={i} mockBrochure={true}/>);
        }

        return <div className="rack-Row">{brochures}<div className="rack-Row-shadow"></div></div>
    }
}

export default Row
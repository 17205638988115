import React from 'react';
import PropTypes from 'prop-types';

// import Banners from './Banners';
// import Links from './Links';
// import Guides from './Guides';
// import Section from './Section';

// Components
import RotatingBanners from '../../../banners/RotatingBanners';

// Contexts
import {LocationContext} from '../../../../contexts/Location';

// CSS
import './ResourceBar.css';

// Utilities
import {bannersInPosition} from '../../../../utils/Banners';


class ResourceBar extends React.Component {
    render() {
        const location = this.props.location;
        if (!location) return null;

        const banners = bannersInPosition(location.banners, 'kiosk-side-banner')

        return (
            <LocationContext.Consumer>{({translations}) => {
                // const generalTranslations = translations.general;

                return <div className="ResourceBar">
                    {banners.length > 0 ?
                        <div className="ResourceBar-banner"><RotatingBanners delay={10} banners={banners} /></div>
                        :
                        <div className="ResourceBar-mock-banner">
                            <div><strong>Digital Ad Space</strong><br/>
                                220 x 830 pixels<br/>
                                @ 72 ppi
                            </div>
                        </div>
                    }
                    {/*
                    <h2>{generalTranslations.travelResources}</h2>
                    <Section title={generalTranslations.travelInfoGuides} show={location.infoguides && location.infoguides.length > 0}>
                        <Guides
                            guides={location.infoguides}
                            onGuideView={this.props.onGuideView}
                            onGuideClick={this.props.onGuideClick}
                        />
                    </Section>
                    <Section show={location.banners && location.banners.length > 0}>
                        <Banners*
                            banners={location.banners}
                            position="resource-bar-banners"
                            onBannerView={this.props.onBannerView}
                            onBannerClick={this.props.onBannerClick}
                        />
                    </Section>
                    <Section title={generalTranslations.quickLinks} show={location.quicklinks && location.quicklinks.length > 0}>
                        <Links
                            links={location.quicklinks}
                            onLinkView={this.props.onQuicklinkView}
                            onLinkClick={this.props.onQuicklinkClick}
                        />
                    </Section>*/ }
                </div>
            }}</LocationContext.Consumer>
        );
    }
}

ResourceBar.propTypes = {
    onGuideView: PropTypes.func.isRequired,
    onGuideClick: PropTypes.func.isRequired,
    onBannerView: PropTypes.func.isRequired,
    onBannerClick: PropTypes.func.isRequired,
    onQuicklinkView: PropTypes.func.isRequired,
    onQuicklinkClick: PropTypes.func.isRequired,
}

export default ResourceBar
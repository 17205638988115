import React from 'react';
import PropTypes from 'prop-types';

// Assets
import AppStoreImg from '../../../assets/img/apple/appstore.png';
import PlayStoreImg from '../../../assets/img/android/playstore.png';
import whiteCategoryArrow from '../../../assets/img/tpl/category-arrow-white.svg';
import './LocationRack.css';

// Components
import Brochure from './brochure/Brochure';
import Dialog from '../../Dialog';
import HotDeals from './hotdeals/HotDeals';
import LoadingSpinner from '../../LoadingSpinner';
import LocationBar from './location-bar/LocationBar';
import Modal from '../../Modal';
import ModalBrowser from '../../ModalBrowser';
import ModalUrlQRCode from '../../ModalUrlQRCode';
import PageHeader from './template/Header~Kiosk';
import Rack from './rack/Rack';
import ResourceBar from './resource-bar/ResourceBar';

import config from '../../../config/config';

// Contexts
import {LocationContext} from '../../../contexts/Location';
import {AppContext} from '../../../contexts/App';

// Utils
import {LogSpace} from '../../../services/Stats';
import AdminModeMonitor from "../../../utils/AdminModeMonitor";
import RoundedButton from '../../RoundedButton';
import Suitcase from './location-bar/Suitcase';
import ScreenManager from '../../../services/ScreenManager';

class LocationRack extends React.Component {
    /**
     * Render this bad boy
     * @returns {XML}
     */
    render() {
        const locationScreen = this.props.locationScreen;
        const location = locationScreen.location;

        return (
            <AppContext.Consumer>{({locale, changeLocale, translations}) => (
                <LocationContext.Provider value={{
                    category: this.state.selectedCategory,
                    changeLocale, // Left here for legacy
                    hideSuitcase: this.closeSuitcase.bind(this),
                    httpCache: this.props.httpCache,
                    isInSuitcase: this.isBrochureInSuitcase.bind(this),
                    locale,
                    location: location,
                    openUrl: this.openUrl.bind(this),
                    openHotDeals: this.openHotDeals.bind(this),
                    showSuitcase: this.openSuitcase.bind(this),
                    saveBrochure: this.saveBrochure.bind(this),
                    stats: this.props.stats,
                    suitcase: this.state.suitcase,
                    suitcaseVisible: this.state.suitcaseVisible,
                    translations // Left here for legacy
                }}>
                    <div className="VisitorTips-background" style={this.backgroundStyle()}/>
                    <div className="LocationRack" ref={this.locationRackRef}>
                        <PageHeader onLogoClick={this.handleLogoClick.bind(this)}
                                    onTagLineClick={this.handleTagLineClick.bind(this)}
                                    httpCache={this.props.httpCache}
                                    kioskMedia={this.props.kioskMedia}
                                    locationScreen={locationScreen}
                                    locationScreens={this.props.locationScreens}
                                    changeLocation={this.handleChangeLocation.bind(this)}
                                    translations={translations}
                                    locale={locale}
                                    changeLocale={changeLocale}
                        />
                        <div className="VisitorTips-body">
                            <div className={'VisitorTips-leftbar' + (this.state.selectedBrochure ? ' hidden' : '')}>
                                <div className="VisitorTips-location-bar">
                                    <LocationBar
                                        location={location}
                                        categories={this.categories(translations)}
                                        category={this.state.selectedCategory}
                                        canChangeLocation={false /*this.props.canChangeLocation*/}
                                        kioskMedia={this.props.kioskMedia}
                                        onChangeCategory={this.handleCategoryChanged.bind(this)}
                                        showChangeLocation={this.showChangeLocation.bind(this)}
                                        showVersion={this.state.enteringAdminCode}
                                    />
                                </div>
                                {
                                    location && location.hotdeals && location.hotdeals.length > 0 ?
                                        <div className="VisitorTips-hot-deals">
                                            <RoundedButton className="FullWidth Category HotDeals" onClick={() => this.openHotDeals()}>
                                                {translations.general.hotDeals}
                                                <img src={whiteCategoryArrow} alt="Category marker"/>
                                            </RoundedButton>
                                        </div>
                                        :
                                        null
                                }

                                <div className="LocationRack-suitcase">
                                    <Suitcase showVersion={this.state.enteringAdminCode}/>
                                </div>

                                <div className="VisitorTips-mobile-apps">
                                    <button onClick={() => this.downloadMobile('iOS', 'https://m.visitortips.com/go/app.html?p=ios')}><img src={AppStoreImg} alt="Download iOS app"/></button>
                                    <button onClick={() => this.downloadMobile('Android', 'https://m.visitortips.com/go/app.html?p=android')}><img src={PlayStoreImg} alt="Download Android app"/></button>
                                </div>
                                {this.state.enteringAdminCode ? <button className="AdminCodeButton" onClick={this.handleHiddenButtonClick.bind(this)}/> : null }
                            </div>
                            <div className={'VisitorTips-main' + (this.state.selectedBrochure ? ' hidden' : '')}>
                                {this.state.loading ?
                                    <Rack cols={12} mockRows={3} />
                                    :
                                    <Rack
                                        cols={10}
                                        rowsPerPage={3}
                                        brochures={this.brochures()}
                                        category={this.state.selectedCategory}
                                        providers={location.providers}
                                        onBrochureClicked={this.handleBrochureClicked.bind(this)}
                                        onProviderClicked={this.handleProviderClicked.bind(this)}
                                        onPageChange={() => this.props.updateLastUserInteraction()}
                                        onRackBrochureView={this.handleRackBrochureView.bind(this)}
                                    />
                                }
                            </div>
                            {this.state.selectedBrochure ? <div className="VisitorTips-brochure">
                                <Brochure brochure={this.state.selectedBrochure}
                                          showDebug={this.state.enteringAdminCode}
                                          onClose={this.closeBrochure}/>
                            </div> : null}
                            <div className="VisitorTips-rightbar">
                                <ResourceBar
                                    location={location}
                                    onGuideView={(guide, ix) => this.handleGuideView(guide, ix)}
                                    onGuideClick={(guide, ix) => this.handleGuideClick(guide, ix)}
                                    onBannerView={(banner, ix) => this.handleBannerView(banner, ix)}
                                    onBannerClick={(banner, ix) => this.handleBannerClick(banner, ix)}
                                    onQuicklinkView={(quicklink, ix) => this.handleQuicklinkView(quicklink, ix)}
                                    onQuicklinkClick={(quicklink, ix) => this.handleQuicklinkClick(quicklink, ix)}
                                />
                            </div>
                        </div>
                        {this.hotDeals(translations)}
                        {this.externalUrlBrowser()}
                    </div>

                    {this.state.loading ?
                        <Modal>
                            <div className="VisitorTips-spinner">
                                <LoadingSpinner label={this.state.loadingText ? this.state.loadingText : translations.general.loading} />
                            </div>
                        </Modal>
                        :
                        null
                    }
                </LocationContext.Provider>
            )}</AppContext.Consumer>
        );
    }

    /**
     * State that the screen should be in when the user actively visits a screen for the first time
     * @returns {{suitcase: [], displayTakeoverWarning: boolean, selectedBrochureIx: number, locale: string, showSuitcase: boolean, browsingUrlLabel: null, showHotDeals: boolean, takeoverWarningCounter: *, translations: {general: {cancel: string, quickLinks: string, funAtYourFingerTips: string, backTo: string, restarting: string, travelInfoGuides: string, "Select Area": string, travelGuides: string, travelResources: string, "Visitor Services": string, "Dining & Entertainment": string, sendText: string, sendEmail: string, hotDeals: string, sending: string, numBrochuresSaved: string, Shopping: string, close: string, "Select a category below:": string, submittingDotDotDot: string, unknownErrorDescription: string, privacyPolicy: string, "Main Destination Rack": string, "Attractions & Activities": string, "Tours & Transportation": string, unknownErrorTitle: string, qrCodeHint: string, loading: string, qrCodeInstructions: string, Accommodations: string, "All Brochures": string, saving: string, "Select City": string}, suitcase: {saveTitle: string, yourSuitcase: string, saveDescription: string}, brochureDetail: {website: string, address: string, addToSuitcase: string, publicationDetail: string, shareBrochure: string, shareBrochureDescription: string, description: string, video: string, shareWithFriend: string, photos: string, print: string, reservations: string, phone: string, downloadPrint: string, detail: string, map: string, inSuitcase: string}, account: {clickHere: string, registerHere: string, signInTitle: string, passwordPrompt: string, signInHere: string, confirmPasswordPrompt: string, textPhone: string, registrationTitle: string, alreadyRegisteredQuestion: string, postalCodePrompt: string, signIn: string, notRegisteredQuestion: string, missingEmailAndPassword: string, logOut: string, email: string}}, browsingUrl: null, selectedCategory: null, selectedBrochure: null, suitcaseVisible: boolean, lastUserInteraction: null}}
     */
    getLoadedState() {
        return {
            browsingUrl: null,
            browsingUrlLabel: null,
            displayTakeoverWarning: false,
            selectedBrochure: null,
            selectedBrochureIx: 0,
            showHotDeals: false,
            showSuitcase: false,
            suitcaseVisible: false,
        };
    }
    /**
     * State that we should be in initially (immediately after the location has been loaded)
     * @returns {{browsingUrl: null, browsingUrlLabel: null, loadingText: null, locale: string, selectedBrochure: null, selectedBrochureIx: number, selectedCategory: null, showSuitcase: boolean, suitcase: Array, translations: *, runningTime: number, lastUserInteraction: null}}
     */
    getResetState() {
        var loadedState = this.getLoadedState();

        return {
            ...loadedState,
            lastUserInteraction: null, // number of seconds since last activity (null=no activity)
            //locale: 'en',
            selectedCategory: null,
            suitcase: [],
            takeoverWarningCounter: config.instance['screenTakeover.warningInterval']
        };
    }

    componentDidMount() {
        // if (this.props.heartbeat) this.props.heartbeat.onTick(this.heartbeatDidTick.bind(this));
        //this.props.screenManager.onResetState(this.handleResetStateRequest.bind(this));
        if (this.props.screenManager) this.props.screenManager.onResetState(this.handleResetStateRequest.bind(this));
    }

    componentWillUnmount() {
        // if (this.props.heartbeat) this.props.heartbeat.removeOnTick(this.heartbeatDidTick);
        if (this.props.screenManager) this.props.screenManager.removeOnResetState(this.handleResetStateRequest.bind(this));
    }

    /**
     * Handle heartbeat tick
     */
    // heartbeatDidTick() {
        // this.checkRunScreenTakeover();
    // }

    /**
     * Start (or restart) from scratch
     */
    launch() {
        if (config.instance.isMouseMoveUserInteraction) this.refs.locationrack.addEventListener('mousemove', () => {
            this.props.updateLastUserInteraction()
        });

        this.setState(this.getResetState());
    }

    /**
     * Get the number of seconds the app has been running
     * @returns {number}
     */
    // getRunningTime() {
    //     return ((new Date()).getTime() - this.startTime.getTime()) / 1000;
    // }

    /**
     * Get the number of seconds since the last user interaction
     * @returns {int|null} NULL When no user interations, or fractional seconds since last user interaction
     */
    getLastUserInteraction() {
        return this.lastUserInteractionTime ? ((new Date()).getTime() - this.lastUserInteractionTime.getTime()) / 1000 : null;
    }

    backgroundStyle() {
        return;
        // Removed support for background colors
        // if (!this.props.locationScreen.location.meta) return null;
        //
        // const background = this.props.locationScreen.location.meta.background;
        // if (!background) return;
        //
        // return {
        //     backgroundColor: background.color ? '#' + background.color : null,
        //     backgroundImage: background.image ? 'url(' + background.image + ')' : null
        // }
    }

   //
    // Convenience methods
    //
    categories(translations) {
        if (this.state.loading) return [
            {id: 0, key: '', name: 'Loading...', translatedName: translations.general.loading}
        ];

        const categories = [
            {id:1, key: 'acc', name: 'Accommodations', translatedName: translations.general['Accommodations']},
            {id:2, key: 'att', name: 'Attractions / Activities', translatedName: translations.general['Attractions & Activities']},
            {id:3, key: 'din', name: 'Dining / Entertainment', translatedName: translations.general['Dining & Entertainment']},
            {id:4, key: 'sho', name: 'Shopping', translatedName: translations.general['Shopping']},
            {id:5, key: 'tou', name: 'Tours / Transportation', translatedName: translations.general['Tours & Transportation']},
            {id:6, key: 'vis', name: 'Visitor Services', translatedName: translations.general['Visitor Services']}
        ];

        const filteredCategories = categories.filter(category => {
            return this.hasBrochuresInCategory(category)
        });

        return filteredCategories.length > 0 ? filteredCategories : categories; // Ensure that there is alsways at least one category displayed
    }

    brochures() {
        if (this.state.loading) return [];

        return this.state.selectedCategory ? this.brochuresInCategory(this.state.selectedCategory) : [...this.props.locationScreen.location.listings];
    }

    /**
     * @param {{id: number, name: string}} filterCategory
     * @returns {*[]}
     */
    brochuresInCategory(filterCategory) {
        let listings = [...this.props.locationScreen.location.listings];

        return listings.filter(listing => listing.categories.filter(listingCategory => listingCategory.name === filterCategory.name).length > 0);
    }

    numBrochuresInCategory(filterCategory) {
        return this.brochuresInCategory(filterCategory).length;
    }

    hasBrochuresInCategory(category) {
        return this.numBrochuresInCategory(category) > 0;
    }

    //
    // Events
    //
    showChangeLocation() {
        this.props.updateLastUserInteraction();
        this.props.showChangeLocation();
    }

    handleChangeLocation(key) {
        this.props.updateLastUserInteraction();
        this.setState(this.getLoadedState(), () => {
            this.props.changeLocation(key);
        });
    }

    handleCategoryChanged(category) {
        this.props.updateLastUserInteraction();
        this.setState({selectedCategory: category});
    }

    handleBrochureClicked(brochure, ix, rowIx, colIx) {
        this.props.updateLastUserInteraction();
        this.setState({
            selectedBrochure: brochure,
            selectedBrochureIx: ix
        });
        this.props.stats.stat(LogSpace.Listings, {
            category: this.state.selectedCategory ? this.state.selectedCategory.id : 0,
            listing: brochure.id,
            location: this.props.locationScreen.location.meta.id,
            statType: 'LPV',
            sortorder: ix + 1
        });
    }

    handleProviderClicked(provider) {
        this.props.updateLastUserInteraction();
        this.openUrl(provider.url);
        this.props.stats.stat(LogSpace.LocationsMisc, {
            location: this.props.locationScreen.location.meta.id,
            statObjtype: 'PARTNER',
            statType: 'C'
        });
    }

    saveBrochure(brochure) {
        this.props.updateLastUserInteraction();
        if (this.isBrochureInSuitcase(brochure)) {
            this.setState({
                suitcase: this.state.suitcase.filter(curBrochure => curBrochure.id !== brochure.id)
            });
        } else {
            this.setState({
                suitcase: [...this.state.suitcase, brochure]
            });
        }
    }

    unsaveBrochure(brochure) {
        this.props.updateLastUserInteraction();
        const suitcase = [...this.state.suitcase];

        suitcase.find(suitcaseBrochure => suitcaseBrochure.id === brochure.id);
        // suitcase.splice()
    }

    //
    // Utility methods
    //
    isBrochureInSuitcase(brochure) {
        return this.state.suitcase.filter(suitcaseBrochure => suitcaseBrochure.id === brochure.id).length > 0;
    }

    closeBrochure() {
        this.props.updateLastUserInteraction();
        this.setState({
            selectedBrochure: null,
            selectedBrochureIx: null
        })
    }

    openUrl(url, urlLabel) {
        this.props.updateLastUserInteraction();
        this.setState({browsingUrl: url, browsingUrlLabel: urlLabel});
    }

    resetOpenUrl() {
        this.props.updateLastUserInteraction();
        this.setState({browsingUrl: null, browsingUrlLabel: null});
    }

    downloadMobile(platform, url) {
        this.openUrl(url);
        this.props.stats.stat(LogSpace.LocationsMisc, {
            location: this.props.locationScreen.location.meta.id,
            statObjtype: 'MOBILEAPP',
            statType: 'C',
            platform
        });
    }

    openHotDeals() {
        this.props.updateLastUserInteraction();
        this.setState({showHotDeals: true});
        this.props.stats.stat(LogSpace.LocationsMisc, {
            location: this.props.locationScreen.location.meta.id,
            statObjType: 'HOTDEAL',
            statType: 'O',
            category: this.state.currentCategory ? this.state.currentCategory.id : 0
        });
    }

    openSuitcase() {
        this.props.updateLastUserInteraction();
        this.setState({suitcaseVisible: true});
    }

    closeSuitcase() {
        this.props.updateLastUserInteraction();
        this.setState({suitcaseVisible: false});
    }

    hotDeals(translations) {
        return this.state.showHotDeals ?
            <Modal allowClickOutside={true} onClose={() => this.setState({showHotDeals: false})}>
                <div style={{width: '80%', margin: '50px auto'}}>
                    <Dialog title={translations.general.hotDeals} onClose={() => this.setState({showHotDeals: false})}>
                        <HotDeals
                            hotDeals={this.props.locationScreen.location.hotdeals}
                            onDealView={this.handleDealView.bind(this)}
                            onDealClick={this.handleDealClick.bind(this)}
                        />
                    </Dialog>
                </div>
            </Modal>
            :
            null
    }

    /**
     * Add statistics for requested object
     * @param statObjType
     * @param statObjId
     * @param statType
     * @param ix
     * @param url
     */
    statLocationMiscView(statObjType, statObjId, statType, ix, url, extraData) {
        const data = {
            location: this.props.locationScreen.location.meta.id,
            statObjType,
            statObjId,
            statType,
            category: this.state.selectedCategory ? this.state.selectedCategory.id : 0,
            sortorder: ix + 1,
            ...extraData
        };

        if (url) data.url = url;

        this.props.stats.stat(LogSpace.LocationsMisc, data);
    }

    /**
     * Check the status of various parts of the system to see if a "view" stat should be sent to the server.
     * For example, a brochure rack brochure should not be statted when the screen takeover is playing
     */
    shouldStatViewStat() {
        return !this.state.browsingUrl &&
            !this.state.showHotDeals &&
            !this.state.selectedBrochure &&
            !this.state.selectedBrochureIx &&
            !this.context.takeoverRunning &&
            (
                this.context.getSecondsSinceLastScreenTakeoverFinished() === null ||
                this.context.getIdleTime() <= this.context.getSecondsSinceLastScreenTakeoverFinished()
            )
    }

    getShouldStatViewStatInfo() {
        return {
            //takeoverRunning: this.screenManagerContext.takeoverRunning,
            browsingUrl: this.state.browsingUrl ? true : false,

            showHotDeals: this.state.showHotDeals ? true : false,
            selectedBrochure: this.state.selectedBrochure ? true : false,
            selectedBrochureIx: this.state.selectedBrochureIx ? true : false,

            // idleTime: this.getIdleTime(),
            timeSinceLastScreenTakeoverFinished: this.context.getSecondsSinceLastScreenTakeoverFinished(),
            shouldRun: this.shouldStatViewStat()
        };
    }

    /**
     * Handle misc location asset view event
     * @param statObjType
     * @param statObjId
     * @param ix
     */
    locationMiscView(statObjType, statObjId, ix) {
        const shouldRun = this.shouldStatViewStat();
        let extraData = config.instance.overrideShouldRunStats ? this.getShouldStatViewStatInfo() : null;

        if (shouldRun || config.instance.overrideShouldRunStats === true) this.statLocationMiscView(statObjType, statObjId, 'V', ix, null, extraData);
    }

    /**
     * Handle misc location asset click event
     * @param statObjType
     * @param statObjId
     * @param url
     * @param ix
     */
    locationMiscClick(statObjType, statObjId, ix, url) {
        this.openUrl(url);
        this.statLocationMiscView(statObjType, statObjId, 'C', ix, url);
    }

    handleDealView(deal, ix) {
        this.locationMiscView('HOTDEAL', deal.id, ix);
    }

    handleDealClick(deal, ix) {
        this.locationMiscClick('HOTDEAL', deal.id, ix, deal.url);
    }

    handleGuideView(guide, ix) {
        this.locationMiscView('GUIDE', guide.id, ix)
    }

    handleGuideClick(guide, ix) {
        this.locationMiscClick('GUIDE', guide.id, ix, guide.url)
    }

    handleBannerView(banner, ix) {
        this.locationMiscView('BANNER', banner.id, ix)
    }

    handleBannerClick(banner, ix) {
        this.locationMiscClick('BANNER', banner.id, ix, banner.url);
    }

    handleQuicklinkView(quicklink, ix) {
        this.locationMiscView('QUICKLINK', quicklink.id, ix)
    }

    handleQuicklinkClick(quicklink, ix) {
        this.locationMiscClick('QUICKLINK', quicklink.id, ix, quicklink.url)
    }

    handleRackBrochureView(brochure, ix, isVisible) {
        const shouldRun = this.shouldStatViewStat();

        if (shouldRun || config.instance.overrideShouldRunStats === true) {
            const data = {
                category: this.state.selectedCategory ? this.state.selectedCategory.id : 0,
                listing: brochure.id,
                location: this.props.locationScreen.location.meta.id,
                statType: 'RCK',
                sortorder: ix + 1,
                visible: isVisible
            };
            if (!shouldRun && config.instance.overrideShouldRunStats === true) data.shouldRun = this.getShouldStatViewStatInfo();

            this.props.stats.stat(LogSpace.Listings, data);
        }
    }

    externalUrlBrowser() {
        return this.state.browsingUrl ?
            config.instance.allowExternalUrl ?
                <ModalBrowser url={this.state.browsingUrl} onClose={() => this.resetOpenUrl()}/>
                :
                <ModalUrlQRCode url={this.state.browsingUrl} label={this.state.browsingUrlLabel} onClose={() => this.resetOpenUrl()} />
            :
            null
    }

    setupAdminHandler() {
        const monitor = new AdminModeMonitor('111');
        monitor.onAcceptingInput(enteringAdminCode => {
            this.setState({enteringAdminCode});
        });
        monitor.onCodeAccepted(() => {
            this.props.onExit();
        });
        this.adminModeMonitor = monitor;
    }

    /**
     * Enable input for the admin code authentication
     */
    handleLogoClick() {
        this.props.updateLastUserInteraction();
        this.props.stats.stat(LogSpace.LocationsMisc, {
            location: this.props.locationScreen.location.meta.id,
            statObjtype: 'LOGO',
            statType: 'C'
        });

        const logoUrl = this.props.locationScreen.logoUrl ? this.props.locationScreen.logoUrl : config.instance.logoUrl;
        console.log('locationScreen: ', logoUrl);
    }

    handleTagLineClick() {
        this.props.updateLastUserInteraction();
        this.adminModeMonitor.enableInput();
    }

    /**
     * Add input to the admin code authentication mechanism
     * @param ev
     */
    handleHiddenButtonClick(ev) {
        ev.preventDefault();
        this.props.updateLastUserInteraction();
        this.adminModeMonitor.addInput('1');
    }
    
    handleResetStateRequest() {
        this.setState({...this.getResetState()});
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.getResetState(),
            location: {},
            enteringAdminCode: false // whether the user is able to enter the admin code
        };
        this.locationRackRef = React.createRef();
        this.lastReset = null;
        this.handleBrochureClicked = this.handleBrochureClicked.bind(this);
        this.handleProviderClicked = this.handleProviderClicked.bind(this);
        this.closeBrochure = this.closeBrochure.bind(this);
        this.setupAdminHandler();
    }
}

LocationRack.contextType = AppContext

LocationRack.propTypes = {
    changeLocation: PropTypes.func.isRequired, // Change the screen directly
    heartbeat: PropTypes.object, // services/Heartbeat
    httpCache: PropTypes.object,
    locationScreen: PropTypes.object, // Location data from API
    locationScreens: PropTypes.array.isRequired,
    onExit: PropTypes.func, // Callback to exit the current screen into admin
    showChangeLocation: PropTypes.func, // To request a screen change
    stats: PropTypes.object, // services/Stats
    updateLastUserInteraction: PropTypes.func.isRequired,
    screenManager: PropTypes.instanceOf(ScreenManager)
};

export default LocationRack
import React from 'react';

import Dialog from './Dialog';
import CachedImage from './CachedImage';
import Modal from './Modal';
import { qrCodeUrl } from '../utils/Url';
import {LocationContext} from '../contexts/Location';

class ModalUrlQRCode extends React.Component {
    render() {
        return <LocationContext>{({translations}) => (
            <Modal allowClickOutside={true} onClose={() => this.props.onClose()}>
                <div style={{width: '500px', margin: '50px auto'}}>
                    <Dialog title={this.props.label ? this.props.label : translations.brochureDetail.website} onClose={() => this.props.onClose()}>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '150px'}}>
                                <CachedImage src={qrCodeUrl(this.props.url)} width="150" height="150" alt="QR code link" />
                            </div>
                            <div style={{paddingLeft: '20px'}}>
                                <p>{translations.general.qrCodeInstructions}</p>
                                <p><em>* {translations.general.qrCodeHint}</em></p>
                            </div>
                        </div>
                        <hr/>
                        {this.friendlyUrl()}
                    </Dialog>
                </div>
            </Modal>
        )}</LocationContext>
    }

    friendlyUrl() {
        let url = this.props.url;

        if (url) {
            url = url.replace(/https?:\/\//, '');
            url = url.replace(/\/.*/, '');
        }

        return url;
    }
}

export default ModalUrlQRCode
import ActionHandler from './ActionHandler';
import ActionProcessor, {ActionRequest} from '../ActionProcessor';

class RetrieveCacheFilesAction implements ActionHandler {
    process(action: ActionRequest, processor: ActionProcessor): Promise<any|void> {
        if (!window.caches) return Promise.resolve(false);

        return window.caches
            .open('location')
            .then(cache => cache.keys())
            .then(keys => keys.map(key => key.url));
    }
}

export default RetrieveCacheFilesAction
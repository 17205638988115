/**
 * Pairs data received from API with the LocationRack component
 */
import React from 'react';
import PropTypes from 'prop-types';
import LocationRack from './location-rack/LocationRack';
import {firstIndexOf} from '../../utils/Arr';
import ToastManager from "../../services/ToastManager";
import ScreenManager from '../../services/ScreenManager';

class LocationRackScreen extends React.Component {
    render() {
        return <LocationRack canChangeLocation={this.canChangeLocation()}
                             changeLocation={this.changeLocation.bind(this)}
                             heartbeat={this.props.heartbeat}
                             httpCache={this.props.httpCache}
                             kioskMedia={this.props.kioskMedia}
                             locationScreen={this.props.screen}
                             locationScreens={this.locationScreens()}
                             onExit={this.props.onExit}
                             screenManager={this.props.screenManager}
                             showChangeLocation={this.showChangeLocation.bind(this)}
                             stats={this.props.stats}
                             toastManager={this.props.toastManager}
                             updateLastUserInteraction={this.props.updateLastUserInteraction}
        />
    }

    locationScreens() {
        return this.props.screens.filter(screen => typeof(screen.showInMenu) === 'undefined' || screen.showInMenu === true);
        // return this.props.screens.filter(screen => screen.type === 'location-rack');
    }

    canChangeLocation() {
        return this.locationScreens().length > 1; // More than one of this screen type must be available in order to allow changing the location
    }

    changeLocation(key) {
        const screenIx = firstIndexOf(this.props.screens, screen => screen.key === key);

        if (screenIx < 0) {
            alert('An error occurred while trying to change the location');
            return;
        }

        this.props.changeScreen(screenIx);
    }

    showChangeLocation() {
        this.props.showChangeScreen(this.props.screen.type);
    }
}

LocationRackScreen.propTypes = {
    cancelChangeScreen: PropTypes.any,
    changeScreen: PropTypes.func.isRequired,
    heartbeat: PropTypes.any,
    httpCache: PropTypes.object,
    kioskMedia: PropTypes.any,
    onExit: PropTypes.any,
    popScreen: PropTypes.func.isRequired,
    screen: PropTypes.any,
    screens: PropTypes.any,
    screenManager: PropTypes.instanceOf(ScreenManager),
    showChangeScreen: PropTypes.func.isRequired,
    stats: PropTypes.any,
    toastManager: PropTypes.instanceOf(ToastManager).isRequired,
    updateLastUserInteraction: PropTypes.func.isRequired
};

export default LocationRackScreen
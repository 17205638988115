export default {
    notRegisteredQuestion: '還沒註冊？',
    registerHere: '在這裡註冊',
    clickHere: '點擊這裡',
    email: '電子郵件',
    textPhone: '文字电话',
    passwordPrompt: '密碼：',
    signInTitle: '登陸',
    registrationTitle: '註冊',
    alreadyRegisteredQuestion: '已經註冊？',
    signInHere: '在此登錄',
    confirmPasswordPrompt: '確認密碼：',
    postalCodePrompt: '郵政編碼：',
    missingEmailAndPassword: '請輸入電子郵件和密碼。',
    logOut: '登出',
    sendEmail: '',
    signIn: '登陸'
}
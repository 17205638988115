const general = {
    unknownErrorTitle: 'Error desconocido',
    unknownErrorDescription: 'Un error desconocido ocurrió. Por favor , inténtelo de nuevo en breve.',
    loading: 'Cargando...',
    sending: 'Enviando',
    sendEmail: 'Enviar correo electrónico',
    sendText: 'Enviar mensaje de texto',
    submittingDotDotDot: 'Enviando...',
    backTo: 'Atrás',
    close: 'Cerca',
    restarting: 'Reinicio',
    cancel: 'Cancelar',

    // Template
    travelResources: 'Recursos del Viajero',
    travelInfoGuides: 'Guía de Viaje Información',
    travelGuides: 'Guía de Viaje Información',
    quickLinks: 'Enlaces Rapidos',
    privacyPolicy: 'Política de privacidad',
    numBrochuresSaved: '%d Salvado',
    saving: 'Adición...',
    hotDeals: 'Descuentos',
    'Select City': 'Ciudad selecta',
    'Select Area': 'Cambiar área', // Change Area
    'Select a category below:': 'Seleccione una categoría a continuación:',
    funAtYourFingerTips: 'Diversión a tu alcance',
    qrCodeInstructions: 'Escanee el código QR de la izquierda con su teléfono móvil o tableta para abrir el sitio web.',
    qrCodeHint: 'pista: usa la aplicación de la cámara en tu iOS y Android',

    // Categories
    'All Brochures': 'Todos los folletos',
    'Main Destination Rack': 'Main Destination Rack',
    'Accommodations': 'Alojamiento',
    'Attractions & Activities': 'Atracciones y actividades',
    'Dining & Entertainment': 'Comedor y entretenimiento',
    'Shopping': 'Compras',
    'Tours & Transportation': 'Tours y Transporte',
    'Visitor Services': 'Servicios para Visitantes'
}

export default general;
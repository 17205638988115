/**
 * Manage admin code input to check when the user has entered the correct code
 */
class AdminModeMonitor {
    /**
     *
     * @param accessCode
     * @param inputTimeout
     */
    constructor(accessCode, inputTimeout=3) {
        if (accessCode.length === 0) {
            throw new Error('Code is required');
        }
        this._onCodeAcceptedHandlers = [];
        this._onAcceptingInputHandlers = [];
        this._accessCode = accessCode;
        this.inputTimeout = inputTimeout;
        this._numClickToEnable = 3; // The number of times enableInput() must be called before input is accepted
        this._reset();
    }

    _reset() {
        this.stopTimer();
        this._codeEntered = '';
        this.inputTimer = null;
        this._acceptInput = false;
        this._onAcceptingInputHandlers.forEach(handler => handler(false));
        this._enableInputCount = 0;
    }

    onCodeAccepted(handler) {
        this._onCodeAcceptedHandlers.push(handler);
    }

    onAcceptingInput(handler) {
        this._onAcceptingInputHandlers.push(handler);
    }

    enableInput() {
        this._enableInputCount ++;
        this._codeEntered = '';
        this.startTimer();

        if (this._enableInputCount >= this._numClickToEnable) {
            this._acceptInput = true;
            this._onAcceptingInputHandlers.forEach(handler => handler(true));
        }
    }

    /**
     * Add an inputted value to the code being enetered
     * @param char
     */
    addInput(char) {
        if (!this._acceptInput) {
            return;
        }

        this.startTimer();

        this._codeEntered += char;

        if (this._codeEntered === this._accessCode) {
            this._onCodeAcceptedHandlers.forEach(handler => handler());
            this._reset();
        }
    }

    startTimer() {
        this.stopTimer();

        this.inputTimer = setTimeout(() => {
            this._reset();
        }, this.inputTimeout*1000);
    }

    stopTimer() {
        if (this.inputTimer) {
            clearTimeout(this.inputTimer);
        }
    }
}

export default AdminModeMonitor
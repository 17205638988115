import React from 'react';
import './Flag.css';

class Flag extends React.Component {
    render() {
        return <button className={'template-Flag' + (this.props.selected ? ' selected' : '')} onClick={e => this.props.onClick(this.props.locale)}>
            <img src={this.props.image} alt={this.props.country + ' flag'}/>
        </button>
    }

    handleClick(e) {
        this.props.onClick(this.props.locale);
    }
}

export default Flag
import React from 'react';
import PropTypes from 'prop-types';

import CodeValue from './CodeValue';
import CodeButton from './CodeButton';

import './CodePad.css';

const KEY_DELETE = 'DELETE';
const KEY_DONE = 'SUBMIT';
const KEY_BACK = 'BACK';

class CodePad extends React.Component {
    render() {
        return <div>
            <div className="CodePad-value">
                {this.valueInput()}
            </div>
            <div className="CodePad-NumberPad">
                <div className="CodePad-button-row">
                    <CodeButton value="1" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="2" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="3" onClick={this.handleValue.bind(this)}/>
                </div>
                <div className="CodePad-button-row">
                    <CodeButton value="4" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="5" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="6" onClick={this.handleValue.bind(this)}/>
                </div>
                <div className="CodePad-button-row">
                    <CodeButton value="7" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="8" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="9" onClick={this.handleValue.bind(this)}/>
                </div>
                <div className="CodePad-button-row">
                    { this.props.onBack ? <CodeButton value={KEY_BACK} onClick={this.handleValue.bind(this)}/> : null }
                    <CodeButton value={KEY_DELETE} onClick={this.handleValue.bind(this)}/>
                    <CodeButton value="0" onClick={this.handleValue.bind(this)}/>
                    <CodeButton value={KEY_DONE} onClick={this.handleValue.bind(this)}/>
                </div>
            </div>
        </div>
    }

    constructor(props) {
        super(props);
        this.numChars = 4;
        this.state = {
            curIndex: 0,
            value: ''
        };
    }

    handleValue(value) {
        if (value === KEY_BACK) {
            this.props.onBack();
        } else if (value === KEY_DONE) {
            this.props.onSubmit(this.state.value.replace(/ +/, ''));
        } else if (value === KEY_DELETE) {
            let newValue = this.state.value;
            let newIx = this.state.curIndex;
            let startIx = this.state.curIndex;

            // If the current character is already blank then assume the user wants to go back one space
            if ((this.state.curIndex > newValue.length - 1 || newValue.substr(this.state.curIndex, 1).trim().length === 0) && newIx > 0) {
                newIx--;
                startIx--;
            }

            newValue = newValue.substr(0, startIx) + ' ' + newValue.substr(startIx+1);

            this.setState({
                curIndex: newIx,
                value: newValue
            });
        } else {
            let newValue = this.state.value;
            newValue = newValue.substr(0, this.state.curIndex) + value + newValue.substr(this.state.curIndex+1);
            this.setState({
                value: newValue,
                curIndex: this.state.curIndex >= this.numChars - 1 ? this.state.curIndex : this.state.curIndex+1
            });
        }
    }

    valueInput() {
        const inputs = [];
        for(let i=0; i < this.numChars; i++) {
            inputs.push(
                <div key={'input-'+i} className="CodePad-value-box">
                    <CodeValue
                        value={this.inputValue(i)}
                        selected={this.state.curIndex === i}
                        onClick={() => {
                            if (i > this.state.value.length) i = this.state.value.length;
                            this.setState({curIndex: i});
                        }}
                    />
                </div>
            );
        }

        return inputs;
    }

    inputValue(i) {
        let value = '';

        if (this.state.value.length > i && this.state.value[i].trim().length > 0) value = this.state.value[i];

        return value;
    }
}

CodePad.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default CodePad
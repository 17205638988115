import React from 'react';
import Dialog from './Dialog';
import LoadingSpinner from './LoadingSpinner';

class Browser extends React.Component {
    render() {
        return <Dialog title="Browser" onClose={() => this.props.onClose()} fillParent={true}>
            {this.props.url ?
                <>
                    {this.state.loading ? <LoadingSpinner label="Loading"/> : null}
                    <iframe ref="iframe" security="restricted" onLoad={e => this.handleUrlLoaded()} title="Browser" src={this.props.url} style={{width:'100%',height:'100%',visibility: this.state.loading ? 'hidden' : 'visible'}} sandbox={this.sandbox()} />
                </>
                :
                'No URL specified'
            }
        </Dialog>
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    handleUrlLoaded() {
        // const iframe = this.refs.iframe;
        // const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        this.setState({loading: false});
    }

    /**
     * @see https://www.w3schools.com/tags/att_iframe_sandbox.asp
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe
     */
    sandbox() {
        const sandbox = [];
        sandbox.push('allow-forms'); // Re-enables form submission
        sandbox.push('allow-pointer-lock'); // Re-enables APIs
        // sandbox.push('allow-popups'); // Re-enables popups
        sandbox.push('allow-same-origin'); // Allows the iframe content to be treated as being from the same origin
        sandbox.push('allow-scripts'); // Re-enables scripts
        // sandbox.push('allow-top-navigation'); // Allows the iframe content to navigate its top-level browsing context
        // sandbox.push('allow-modals'); // Allows the embedded browsing context to open modal windows.
        // sandbox.push('allow-orientation-lock'); // Allows the embedded browsing context to disable the ability to lock the screen orientation.
        // sandbox.push('allow-pointer-lock'); // Allows the embedded browsing context to use the Pointer Lock API.
        // sandbox.push('allow-popups-to-escape-sandbox'); // Allows a sandboxed document to open new windows without forcing the sandboxing flags upon them. This will allow, for example, a third-party advertisement to be safely sandboxed without forcing the same restrictions upon a landing page.
        sandbox.push('allow-presentation'); // Allows embedders to have control over whether an iframe can start a presentation session.
        // sandbox.push('allow-top-navigation-by-user-activation'); // Allows the embedded browsing context to navigate (load) content to the top-level browsing context only when initiated by a user gesture. If this keyword is not used, this operation is not allowed.

        return sandbox.join(' ');
    }
}

export default Browser
import config from '../config/config';

function mobileUrl(path) {
    return config.instance.mobileBaseUrl + path;
}

function qrCodeUrl(url) {
    return 'https://chart.googleapis.com/chart?cht=qr&chs=150x150&choe=UTF-8&chld=L&chl=' + encodeURIComponent(url);
}

/**
 * @param address
 * @param mapWidth
 * @param mapHeight
 * @returns {string}
 */
function mapImageUrl(address, mapWidth, mapHeight) {
    mapWidth = mapWidth ? mapWidth : config.instance.map.width;
    mapHeight = mapHeight ? mapHeight : config.instance.map.height;

    return 'https://maps.google.com/maps/api/staticmap?markers=' + encodeURIComponent(address) + '&size=' + mapWidth + 'x' + mapHeight + '&key=' + encodeURIComponent(config.instance.map.apiKey);
}

/**
 * Format address object {street1, street2, city, state, postalCode} as string
 * @param address
 * @returns {string}
 */
function addressStr(address) {
    let mapAddress = '';

    if (address.street1.length > 0) mapAddress+= address.street1;
    if (address.city.length > 0) {
        if (mapAddress.length > 0) mapAddress += ',';
        mapAddress += address.city;
    }

    if (address.state.length > 0) {
        if (mapAddress.length > 0) mapAddress += ',';
        mapAddress += address.state;
    }

    if (address.postalCode.length > 0) {
        if (mapAddress.length > 0) mapAddress += ' ';
        mapAddress += address.postalCode;
    }

    return mapAddress;
}
export { mobileUrl, qrCodeUrl, mapImageUrl, addressStr }
//import App from '../App';
import ActionHandler from './Actions/ActionHandler';
import ReloadDataAction from './Actions/ReloadData';
import RestartAction from './Actions/Restart';
import RetrieveCachesAction from './Actions/RetrieveCaches';
import RetrieveCacheFilesAction from './Actions/RetrieveCacheFiles';
import DeleteCacheFileAction from './Actions/DeleteCacheFile';
import ClearLocationCacheAction from './Actions/ClearLocationCache';
import ToastManager from './ToastManager';
import {apiPutData} from './API';
import config from '../config/config';

type App = {
    toastManager: ToastManager
    restart: () => void
    refresh: (showLoadingStatus?: boolean) => Promise<any>
    httpCache: CacheStorage
}

// Action request sent by server
export type ActionRequest = {
    id: string
    action: string
    data: {[key: string]: any}
}

type SuccessData = {
    status: string,
    data?: any
}

class ActionProcessor {
    private actionHandlers: {[key:string]: ActionHandler} = {}
    private queue: Array<ActionRequest> = [];
    private isProcessing: boolean = false;

    constructor(public app: App) {
    }

    registerAction(key: string, action: ActionHandler): void {
        this.actionHandlers[key] = action;
    }

    processActions(actions: Array<ActionRequest>) {
        this.queue = this.queue.concat(actions);
        this.processNextAction();
    }

    // Only process one action at a time
    processNextAction() {
        if (this.isProcessing || this.queue.length === 0) return;

        this.isProcessing = true; // ensure this method cannot be called while it is running

        const actionKey = this.queue[0].action;
        const endPoint = '/kiosk/devices/' + config.instance.kioskRegistrationId + '/actions/' + this.queue[0].id;

        if (typeof(this.actionHandlers[actionKey]) === 'undefined') {
            apiPutData(endPoint, {status: 'unsupported'})
                .then(() => this.finishedProcessingAction())
        } else {
            const handler = this.actionHandlers[actionKey];
            handler.process(this.queue[0], this)
                .then(result => {
                    const data: SuccessData = {
                        status: 'completed'
                    };
                    if (typeof(result) === 'object') data.data = result;

                    apiPutData(endPoint, data)
                        .then(() => this.finishedProcessingAction())
                        .catch(err => console.log('apiPutData.Error', err));
                }).catch(error => {
                    apiPutData(endPoint, {status: 'failed', error})
                        .then(() => this.finishedProcessingAction())
                });
        }
    }

    finishedProcessingAction() {
        this.queue.shift();
        this.isProcessing = false;
        this.processNextAction();
    }

    private processAction(key: string, action: ActionRequest): Promise<any> {
        return this.actionHandlers[key].process(action, this);
    }

    alert(msg: string) {
        this.app.toastManager.alert(msg);
    }

    static create(app: App) {
        const processor = new ActionProcessor(app);

        processor.registerAction('Reload Data', new ReloadDataAction());
        processor.registerAction('Restart', new RestartAction())
        processor.registerAction('Retrieve Caches', new RetrieveCachesAction())
        processor.registerAction('Retrieve Cache Files', new RetrieveCacheFilesAction());
        processor.registerAction('Delete Cache File', new DeleteCacheFileAction())
        processor.registerAction('Clear Location Cache', new ClearLocationCacheAction())

        return processor;
    }
}

export default ActionProcessor
import React from 'react';
import './Brochure.css';
import './MockBrochure';
import MockBrochure from "./MockBrochure";
import { LocationContext } from '../../../../contexts/Location';
import CachedImage from "../../../CachedImage";

import config from '../../../../config/config';

class RackThumbnail extends React.Component {
    render() {
        return (
            <LocationContext.Consumer>{({httpCache}) => (
                <button onClick={this.handleClick} className="rack-Brochure" style={{maxWidth:config.instance.targetBrochureWidth}}>
                    {this.thumbnail(httpCache)}
                </button>
            )}</LocationContext.Consumer>
        );
    }

    handleClick(e) {
        e.preventDefault();
        this.props.onBrochureClicked(this.props.brochure)
    }

    thumbnail(httpCache) {
        var thumbnails = this.props.brochure.media.filter(media => {
            return media.mediaKey === 'brochure-front' && media.variation === 'thumbnail';
            // return media.mediaKey === 'brochure-front' && media.variation === '0';
        });

        var img = null;
        if (thumbnails.length > 0) {
            img = <CachedImage src={thumbnails[0].url} httpCache={httpCache}/>
            img = <div className="rack-Brochure-container" style={{maxWidth:config.instance.targetBrochureWidth}}>{img}</div>
        } else {
            img = <MockBrochure text="Coming Soon" showLoader={false}/>
        }

        return img;
    }

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.imgRef = React.createRef();
        this.state = {
            imageLoaded: false
        };
    }
}

export default RackThumbnail
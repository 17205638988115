export default {
    unknownErrorTitle: 'Erreur inconnue',
    unknownErrorDescription: 'Une erreur inconnue est survenue. S\'il vous plaît essayer à nouveau bientôt.',
    loading: 'Chargement...',
    sending: 'Envoi en cours',
    sendEmail: 'Envoyer un e-mail',
    sendText: 'Envoyer un SMS',
    submittingDotDotDot: 'Envoi en cours...',
    backTo: 'Arrière',
    close: 'Fermer',
    restarting: 'Redémarrage',
    cancel: 'Annuler',

    // Template
    travelResources: 'Ressources de Voyage',
    travelInfoGuides: 'Voyage Guide Infos',
    travelGuides: 'Voyage Guide Infos',
    quickLinks: 'Lien rapide',
    privacyPolicy: 'Politique de Confidentialité',
    numBrochuresSaved: '%d Enregistré',
    saving: 'Ajout...',
    hotDeals: 'Réductions',
    'Select City': 'Sélectionnez une ville',
    'Select Area': 'Changer de zone', // Change Area
    'Select a category below:': 'Sélectionnez une catégorie ci-dessous:',
    funAtYourFingerTips: 'Du plaisir à portée de main',
    qrCodeInstructions: 'Scannez le code QR à gauche avec votre téléphone portable ou votre tablette pour ouvrir le site Web.',
    qrCodeHint: 'astuce: utilisez l\'application appareil photo sur votre iOS et Android',

    // Categories
    'All Brochures': 'Toutes les brochures',
    'Main Destination Rack': 'Main Destination Rack',
    'Accommodations': 'Hébergement',
    'Attractions & Activities': 'Attractions & Activités',
    'Dining & Entertainment': 'Dining & Divertissement',
    'Shopping': 'Achats',
    'Tours & Transportation': 'Tours & Transport',
    'Visitor Services': 'Services aux Visiteurs'
}
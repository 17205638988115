import React from 'react';
import PropTypes from 'prop-types';

// Assets
import logo from '../../../../assets/img/logo.svg';
import us from '../../../../assets/img/flags/us.png';
import de from '../../../../assets/img/flags/de.png';
import fr from '../../../../assets/img/flags/fr.png';
import mx from '../../../../assets/img/flags/mx.png';
import cn from '../../../../assets/img/flags/cn.png';
// import facebookIcon from '../../../../assets/img/social/i_facebook.gif';
// import twitterIcon from '../../../../assets/img/social/i_twitter.gif';
// import youTubeIcon from '../../../../assets/img/social/i_youtube.gif';
// import pinterestIcon from '../../../../assets/img/social/i_pinterest.gif';

import './Header~Kiosk.css';

// import socialLinks from '../../../../config/social';

// Components
import Flag from './Flag';
// import RotatingBanners from '../../../banners/RotatingBanners';
// Contexts
import CachedImage from '../../../CachedImage';
import RoundedButton from '../../../RoundedButton';

// Utilities
// import {bannersInPosition} from '../../../../utils/Banners';

class Header extends React.Component {
    getCobrandLogo() {
        const logo = this.props.kioskMedia.filter(media => media.type === 'brand-logo');
        return logo.length > 0 ? logo[0] : null;
    }
    render() {
        const cobrandLogo = this.getCobrandLogo();

        return <div className="Header">
            <div className="Header-left">
                {cobrandLogo ?
                    <button className="Header-logo-button" onClick={this.handleLogoClick.bind(this)} onContextMenu={this.handleLogoContextClick.bind(this)}>
                        <CachedImage httpCache={this.props.httpCache} src={cobrandLogo.data} className="Header-brand-logo" alt="VisitorTips Logo"/></button>
                    :
                    <button className="Header-logo-button" onClick={this.handleLogoClick.bind(this)} onContextMenu={this.handleLogoContextClick.bind(this)}>
                        <img src={logo} className="Header-logo" alt="VisitorTips Logo"/></button>
                }
            </div>
            <div className="Header-center">
                {this.props.locationScreens.length > 1 ?
                    <div className="Header-change-location text-center">
                        <strong>{this.props.translations.general['Select Area']}</strong>
                        <div className="Header-change-buttons">
                        {this.props.locationScreens.map(locationScreen => {
                            return <RoundedButton className={'ChangeLocationScreen' + (this.props.locationScreen.key === locationScreen.key ? ' Selected' : '')} onClick={() => this.props.changeLocation(locationScreen.key)} key={locationScreen.key}>{locationScreen.name}</RoundedButton>
                        })}
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            <div className="Header-right">
                <div className={'Header-fun i18n-text-' + this.props.locale} onClick={this.handleTagLineClick.bind(this)} style={{userSelect: 'none'}}>{this.props.translations.general.funAtYourFingerTips}</div>
                <div className="Header-flags">
                <Flag locale="en" image={us} country="United States" onClick={this.props.changeLocale} selected={this.props.locale === 'en'}/>
                    <Flag locale="es" image={mx} country="Mexico" onClick={this.props.changeLocale} selected={this.props.locale === 'es'}/>
                    <Flag locale="de" image={de} country="Germany" onClick={this.props.changeLocale} selected={this.props.locale === 'de'}/>
                    <Flag locale="fr" image={fr} country="French" onClick={this.props.changeLocale} selected={this.props.locale === 'fr'}/>
                    <Flag locale="zh" image={cn} country="China" onClick={this.props.changeLocale} selected={this.props.locale === 'zh'}/>
                </div>
            </div>
        </div>
    }

    handleLogoClick(ev) {
        ev.preventDefault();
        this.props.onLogoClick();
    }

    handleTagLineClick(ev) {
        ev.preventDefault();
        this.props.onTagLineClick();
    }

    handleLogoContextClick(ev) {
        ev.preventDefault();
        /* global chrome */
        if (chrome && chrome.runtime && chrome.runtime.sendMessage) chrome.runtime.sendMessage({action: 'Restart'});
    }
}

Header.propTypes = {
    httpCache: PropTypes.object,
    kioskMedia: PropTypes.array.isRequired,
    locationScreen: PropTypes.object, // Current location
    locationScreens: PropTypes.array.isRequired, // All locations on this kiosk
    changeLocation: PropTypes.func.isRequired, // Directly change the screen to another location
    onLogoClick: PropTypes.func.isRequired,
    onTagLineClick: PropTypes.func.isRequired,
    translations: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    changeLocale: PropTypes.func.isRequired
}


export default Header
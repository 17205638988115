import React from 'react';
import Browser from './Browser';
import Button from './Button';
import Modal from './Modal';

class ModalBrowser extends React.Component {
    render() {
        return <Modal fillParent={true} style={{position: 'absolute', top: '10px', right: '10px', bottom: '10px', left: '10px', display: 'flex', flexDirection: 'column'}} onClose={() => this.props.onClose()}>
            <div style={{marginBottom: '10px'}}><Button className="Primary" label="Go Back" onClick={() => this.props.onClose()}/></div>
            <div style={{position: 'relative', flexGrow: 1}}>
                <Browser url={this.props.url} onClose={() => this.props.onClose()}/>
            </div>
        </Modal>
    }
}

export default ModalBrowser
import React from 'react';

import de from '../i18n/de/all';
import defaultLocale from '../i18n/default/all';
import es from '../i18n/es/all';
import fr from '../i18n/fr/all';
import zh from '../i18n/zh/all';

const translations = {
    default: defaultLocale,
    de,
    es,
    fr,
    zh
};

const AppContext = React.createContext({
    changeLocale: (locale) => alert('changeLocale(...) not implemented'),
    getIdleTime: () => alert('getIdleTime() not implemented'),
    getSecondsSinceLastScreenTakeoverFinished: () => alert('getSecondsSinceLastScreenTakeoverFinished(...) not implemented'),
    locale: 'en',
    takeoverRunning: false,
    translations
});

export { AppContext, translations }
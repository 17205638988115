import React from 'react';
import PropTypes from 'prop-types';

// Assets
import './LocationBar.css';
import logo from '../../../../assets/img/logo.svg';

// Components
import Categories from './Categories';
import RoundedButton from '../../../RoundedButton';

class LocationBar extends React.Component {
    /**
     * If the kiosk has a brand logo specified then the VisitorTips logo will be displayed at the top of this location bar
     */
    isBranded() {
        return this.props.kioskMedia.filter(media => media.type === 'brand-logo').length > 0;
    }

    render() {
        return <div className="LocationBar">
            {this.isBranded() ? <button /*onClick={this.handleLogoClick.bind(this)}*/><img src={logo} className="LocationBar-logo" alt="VisitorTips Logo"/></button> : null}
            {this.props.canChangeLocation ?
                <div className="LocationBar-change">
                    <RoundedButton label={this.props.location.meta.name} className="FullWidth ChangeLocation" onClick={this.props.changeLocation} />
                </div>
                :
                null
            }

            {/*<div className="LocationBar-module">*/}
                <Categories categories={this.props.categories} category={this.props.category} onChangeCategory={this.props.onChangeCategory}/>
            {/*</div>*/}
        </div>
    }
}

LocationBar.propTypes = {
    kioskMedia: PropTypes.array.isRequired
}

export default LocationBar
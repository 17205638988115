import React from 'react';
import PropTypes from 'prop-types';

// Components
import Dialog from '../Dialog';
import Modal from '../Modal';
import ScreenSelector from './ScreenSelector';

// Services
import ToastManager from '../../services/ToastManager';
import {LogSpace} from '../../services/Stats';
import Heartbeat from '../../services/Heartbeat';
import ScreenManagerService from '../../services/ScreenManager';
import {AppContext} from '../../contexts/App';

class ScreenManager extends React.Component {

    render() {
        return <>
            {this.screen()}
            {this.state.selectingScreen ?
                <ScreenSelector screens={this.props.screens}
                                showIxes={this.state.screenIxes}
                                currentScreenIx={this.state.screenIxStack[this.state.screenIxStack.length-1]}
                                onSelectScreen={this.changeScreen.bind(this)}
                                httpCache={this.props.httpCache}
                />
                :
                null
            }
        </>;
    }

    screen() {
        if (this.props.screens.length === 0) return <div>Missing Screens</div>;
        else if (this.state.screenIxStack.length === 0) return <div>Missing screenIxStack {this.state.screenIxStack}</div>;
        else if (this.state.screenIxStack[this.state.screenIxStack.length-1] < 0) return <div>This kiosk is misconfigured</div>;

        const screenData = this.props.screens[this.state.screenIxStack[this.state.screenIxStack.length-1]];
        const screenType = screenData.type;

        if (typeof(this.props.screenManager.getType(screenType)) === 'undefined') {
            const newScreenIx = this.props.screenManager.getFirstScreenIxSupported(this.props.screens);
            const isBroken = newScreenIx === this.state.screenIxStack[this.state.screenIxStack.length-1]; // If the first supported screen is not supported on this device then we are in trouble; otherwise we can save the day by sending the user back to the default screen
            const message = isBroken ? 'The kiosk software needs to be updated' : 'This screen is not supported on this kiosk version.';

            if (!isBroken) { // We should not be here, but since this kiosk supports other screens we will try to rescue the session
                setTimeout(() => this.setState({screenIxStack: [newScreenIx]}), 2000);
            }

            return <Modal><div style={{width:400, margin:'20px auto'}}><Dialog>{message}</Dialog></div></Modal>;
        }

        const ScreenComponent = this.props.screenManager.getType(screenType);

        return <ScreenComponent cancelChangeScreen={this.cancelChangeScreen.bind(this)}
                                changeScreen={this.changeScreen.bind(this)}
                                heartbeat={this.props.heartbeat}
                                httpCache={this.props.httpCache}
                                // kiosk={this.props.kiosk}
                                kioskMedia={this.props.media}
                                onExit={this.props.onExit}
                                popScreen={this.popScreen.bind(this)}
                                screen={screenData}
                                screens={this.props.screens}
                                screenManager={this.props.screenManager}
                                showChangeScreen={this.showChangeScreen.bind(this)}
                                stats={this.props.stats}
                                toastManager={this.props.toastManager}
                                updateLastUserInteraction={this.props.updateLastUserInteraction}
        />
    }

    showChangeScreen(filterType=null) {
        const screenIxes = []; // keep track of screen IXes that can be selected

        this.props.screens.forEach((screen, ix) => {
            const visible = typeof(screen.showInMenu) === 'undefined' || screen.showInMenu === true;

            if (visible && (filterType === null || screen.type === filterType)) screenIxes.push(ix);
        });

        this.setState({
            selectingScreen: true,
            screenIxes
        });
    }

    cancelChangeScreen() {
        this.setState({
            selectingScreen: false
        });
    }

    changeScreen(screenIx, presentationType) {
        const screen = this.props.screens[screenIx];
        let screenIxStack = [];

        const usePresentationType = presentationType ? presentationType : screen.defaultPresentation;

        this.props.stats.stat(LogSpace.Screen, {
            type: screen.type,
            name: screen.name
        });

        switch(usePresentationType) {
            // case 'modal'
            case 'push':
                screenIxStack = [...this.state.screenIxStack, screenIx];
                break;
            default: // undefined or 'change'
                screenIxStack = [screenIx];
                break;
        }

        this.setState({
            selectingScreen: false,
            screenIxStack: screenIxStack
        });
    }

    popScreen() {
        if (this.state.screenIxStack.length <= 1) {
            this.showChangeScreen();
        } else {
            const screenIxStack = [...this.state.screenIxStack];
            screenIxStack.pop();
            this.setState({screenIxStack});
        }
    }

    getResetState() {
        return {
            screenIxes: [], // Subset of this.props.screens to be displayed
            screenIxStack: [this.props.screenManager.getFirstScreenIxSupported(this.props.screens)],
            selectingScreen: false
        };
    }

    handleResetState() {
        this.setState({...this.getResetState()});
    }
    
    componentDidMount() {
        if (this.props.screenManager) this.props.screenManager.onResetState(this.handleResetState.bind(this));
        this.setState({screenIxStack: [this.props.screenManager.getFirstScreenIxSupported(this.props.screens)]});
    }
    
    componentWillUnmount() {
        if (this.props.screenManager) this.props.screenManager.removeOnResetState(this.handleResetState.bind(this));
    }

    constructor(props) {
        super(props);

        this.httpCache = null;

        this.state = {...this.getResetState()};
    }
}

ScreenManager.contextType = AppContext

ScreenManager.propTypes = {
    heartbeat: PropTypes.instanceOf(Heartbeat).isRequired,
    httpCache: PropTypes.object,
    // SEEMS TO BE CAUSING AN ERROR: screens: PropTypes.arrayOf(React.Component).isRequired,
    media: PropTypes.array,
    screens: PropTypes.array,
    screenIxStack: PropTypes.array,
    screenManager: PropTypes.instanceOf(ScreenManagerService).isRequired,
    stats: PropTypes.object.isRequired,
    // kiosk: PropTypes.object,
    toastManager: PropTypes.instanceOf(ToastManager).isRequired,
    onExit: PropTypes.func.isRequired,
    updateLastUserInteraction: PropTypes.func.isRequired
};

export default ScreenManager
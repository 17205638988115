/**
 * Merge default and environment configuration
 */
const VERSION = '2.3.2.1';

const BuildType = {
    UKNOWN: 'unknown',
    DEVELOPMENT: 'dev',
    SANDBOX: 'sandbox',
    STAGING: 'staging',
    PRODUCTION: 'production'
}

let buildType = BuildType.PRODUCTION;

if (process.env.REACT_APP_BUILD_ENV === 'development') buildType = BuildType.DEVELOPMENT;
else if (process.env.REACT_APP_BUILD_ENV === 'sandbox') buildType = BuildType.SANDBOX;
else if (process.env.REACT_APP_BUILD_ENV === 'staging') buildType = BuildType.STAGING;
else if (process.env.NODE_ENV === 'development') buildType = BuildType.DEVELOPMENT;

const defaultConfig = {
    apiBaseUrl: 'https://api.visitortips.com',
    logoUrl: 'https://m.visitortips.com/?device=kiosk',
    appCssClass: '',
    buildType,
    demoMode: false,
    heartbeatInterval: 60, // send heartbeat signal to API every X seconds
    maxInitConnectionChecks: 5,
    isMouseMoveUserInteraction: false,// Whether moving the mouse consitutes user interaction
    recheckOnlineInterval: 15, // If the app detects that it is offline, re-check in X seconds
    mobileBaseUrl: 'https://m.visitortips.com',

    useCacheApi: true,
    allowExternalUrl: false,
    overrideShouldRunStats: false, // Should stats be sent for various asset views, even when shouldStatViewStat is false

    // 'screenTakeover.closeOnInteraction': true,
    'screenTakeover.timeout': 90,// 120,
    'screenTakeover.warningInterval': 0, // number of seconds; 0 to disable
    showCropBox: false,
    map: {
        width: 452,
        height: 250,
        apiKey: 'AIzaSyAuUmLGpmUQSt7i6x5fMUpyvZmfzguTHwQ'
    },
    power: {
        scheduledRestart: {
            interval: 12 * 60, // minimum number of minutes before a restart will occur
            windows: [['00:00', '07:00'], ['19:00','23:59']]
        }
    },
    initShowInfoDelay: 5, // Number of seconds to display the startup info (IP address) during startup
    targetBrochureWidth: 125
};

const dev = {
    apiBaseUrl: 'http://api.visitortips',
    // appCssClass: 'hd',
    heartbeatInterval: 6,
    isMouseMoveUserInteraction: true,
    // maxInitConnectionChecks: 0,
    mobileBaseUrl: 'http://m.visitortips',
    recheckOnlineInterval: 5,
    showCropBox: false,
    initShowInfoDelay: 0,
    'screenTakeover.timeout': 6
};

const staging = {
    // heartbeatInterval: 5,
    initShowInfoDelay: 0
};

const prod = {};

const sandbox = {
    ...prod,
    // 'screenTakeover.timeout': 6
}

let envConfig = {};

if (buildType === BuildType.DEVELOPMENT) envConfig = dev;
else if (buildType === BuildType.STAGING) envConfig = staging;
else if (buildType === BuildType.SANDBOX) envConfig = sandbox;
else envConfig = prod;

function recursiveMerge(srcConfig, config, depth=1) {
    var keys = Object.keys(config);
    // Iterate through keys of object
    for(let i=0, j=keys.length; i < j; i++) {
        var key = keys[i];
        var val = config[key];

        if (typeof(config[key]) == 'object') {
            // Make sure srcConfig[key] is object
            if (typeof(srcConfig[key]) !== 'object') srcConfig[key] = {};

            recursiveMerge(srcConfig[key], config[key], depth+1);
        } else {
            // Set value directly
            srcConfig[key] = val;
        }
    }
}

function mergeConfig() {
    var config = {...arguments[0]};

    for(let i=1; i < arguments.length; i++) {
        recursiveMerge(config, arguments[i]);
    }

    return config;
}

const mergedConfig = mergeConfig(
    defaultConfig,
    envConfig,
    {
        kioskVersion: VERSION,
        kioskId: 0,
        kioskRegistrationId: 0
    }
);

const singleton = Symbol();
const singletonEnforcer = Symbol();

class Config {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');
    }

    /**
     * Returned a merged copy of the config above (can then be globally manipulated by external objects)
     * @returns {*}
     */
    static get instance() {
        if (!this[singleton]) {
            this[singleton] = mergedConfig;
        }
        return this[singleton];
    }
}
export default Config
export { BuildType }
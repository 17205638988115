export default {
    publicationDetail: '宣傳冊資料',
    detail: '信息',
    address: '住址',
    description: '描寫',
    website: '網站',
    reservations: '預訂',
    phone: '打電話',
    map: '地图',
    video: '视频',
    photos: '相片',
    addToSuitcase: '保存',
    inSuitcase: '保存',
    downloadPrint: '下载',
    print: '打印',
    shareWithFriend: '分享',
    shareBrochure: '分享手册',
    shareBrochureDescription: '将此小册子信息的副本发送给您自己或朋友。'
}
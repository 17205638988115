import ToastMessage from './ToastMessage';

class ToastManager {
    constructor() {
        this.messages = [];
        this.onChangedHandlers = [];
    }

    /**
     * @param message
     * @param timeout_ms milliseconds until message disappears.  0 = user closed
     */
    alert(message, timeout_ms= 5000) {
        const toastMessage = new ToastMessage(message, new Date());
        return new Promise(resolve => {
            if (timeout_ms > 0) {
                setTimeout(() => {
                    this.closeToast(toastMessage);
                    resolve();
                }, timeout_ms);
            }
            this.messages.unshift(toastMessage);
            if (timeout_ms <= 0) resolve();
            this._triggerChanged();
        });
    }

    closeToast(toastMessage) {
        this.messages = this.messages.filter(message => message !== toastMessage);
        this._triggerChanged();
    }

    clostToastAtIndex(ix) {
        this.messages = this.messages.filter((message, i) => i !== ix);
        this._triggerChanged();
    }

    onMessages(callback) {
        this.onChangedHandlers.push(callback);
    }

    _triggerChanged() {
        this.onChangedHandlers.forEach(handler => {
            handler(this.messages);
        });
    }

    removeOnChanged(callback) {
        this.onChangedHandlers = this.onChangedHandlers.filter(cb => cb !== callback);
    }
}

export default ToastManager
import React from 'react';
import PropTypes from 'prop-types';

import './ScreenCropBox.css';

class ScreenCropBox extends React.Component {
    render() {
        return (<div className="ScreenCropBox" style={{width: this.props.width, height: this.props.height}}>
            <div className="ScreenCropBox-label">{this.props.width}x{this.props.height}</div>
        </div>)
    }
}

ScreenCropBox.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
};

export default ScreenCropBox
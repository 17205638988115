export default {
    unknownErrorTitle: '未知錯誤',
    unknownErrorDescription: '出現未知錯誤。請稍後再試。',
    loading: '載入中...',
    sending: '正在发送',
    sendEmail: '发电子邮件',
    sendText: '发短讯',
    submittingDotDotDot: '提交...',
    backTo: '背部',
    close: '关',
    restarting: '重新开始',
    cancel: '取消',

    // Template
    travelResources: '資源',
    travelInfoGuides: '旅遊信息指南',
    travelGuides: '旅遊信息指南',
    quickLinks: '快速鏈接',
    privacyPolicy: '隱私政策',
    numBrochuresSaved: '%d 保存',
    saving: '添加...',
    hotDeals: '折扣',
    'Select City': '选择城市',
    'Select Area': '变更区域', // Change Area
    'Select a category below:': '在下面选择一个类别：',
    funAtYourFingerTips: '在您的指尖乐趣',
    qrCodeInstructions: '使用手机或平板电脑扫描左侧的QR码以打开网站。',
    qrCodeHint: '提示：在iOS和Android上使用相机应用',

    // Categories
    'All Brochures': '所有宣传册',
    'Main Destination Rack': 'Main Destination Rack',
    'Accommodations': '旅遊',
    'Attractions & Activities': '旅遊景點及活動',
    'Dining & Entertainment': '餐飲娛樂',
    'Shopping': '購物',
    'Tours & Transportation': '旅遊與運輸',
    'Visitor Services': '觀眾服務',
}
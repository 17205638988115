import React from 'react';
import LoadingSpinner from '../../../LoadingSpinner';

import './MockBrochure.css';

class MockBrochure extends React.Component {
    render() {
        const showLoader = typeof(this.props.showLoader) === 'undefined' || this.props.showLoader === true;

        return <div className="rack-MockBrochure">
            <div className="rack-MockBrochure-loader">
                {showLoader ? <LoadingSpinner size="small" speed="slow" /> : null }
                {this.props.text ? this.props.text : null}
            </div>
        </div>
    }
}

export default MockBrochure
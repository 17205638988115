import React from 'react';
import './Dialog.css';

class Dialog extends React.Component {
    render() {
        const hasHeader = this.props.title.length > 0 || this.props.onClose;

        return <div className={'Dialog ' + (this.props.fillParent ? 'Dialog-fill-parent' : '') + (hasHeader ? 'has-header' : '')}>
            {hasHeader ?
                <div className={'Dialog-header ' + (this.props.title.length > 0 ? 'has-title' : '')}>
                    <label>{this.props.title}</label>
                    {this.props.onClose ?
                        <button className="Dialog-close" onClick={e => this.props.onClose()}><span>x</span>
                        </button> : null}
                </div>
                :
                null
            }
            <div className="Dialog-body">{this.props.children}</div>
        </div>
    }
}

Dialog.defaultProps = {
    title: ''
}

export default Dialog